@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductPrice {
    white-space: nowrap;

    &-Price :has(.ProductPrice-PriceBadge) {
        .ProductPrice-PriceValue {
            margin-block-start: 0px;
        }
    }
     
    &-Price {
        flex-wrap: nowrap;
        padding-inline: 0.2rem;
    }

    &-PriceValue {
        margin-block-start: 6px;
        line-height: 24px;
    }
    
    del {
        font-size: 14px;
        font-weight: normal;
        opacity: .48;
        display: inline-block;
        margin-inline-end: 0;

        @include mobile {
            margin-block-start: 0;
            font-size: 12px;
        }

        @include smallmobile {
            font-size: 12px
        }
    }

    &-PriceBadge {
        font-size: 12px;
    }

    &-Discount {
        font-size: 16px;
        font-weight: 500;
        color: var(--primary-base-color);

        @include mobile {
            font-size: 12px;
        }
    }
}
