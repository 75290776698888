@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.PageBuilderProducts {
    padding: 2rem 0;

    @include mobile {
        padding: 0px;
    }

    .ProductCard {
        &-Content {
            .ProductActions-AttributesWrapper {
                .ProductCard-Attributes {
                    .ProductConfigurableAttributes-SwatchList {
                        max-width: 260px;
                    }
                }
            }
        }
    }

    .swiper-slide {
        @include mobile {
            width: 100%;
            margin-inline-end: -150px;
        }
    }

    .swiper-pagination-progressbar {
        display: none;

        @include mobile {
            display: block;
        }
    }

    .swiper-pagination {
        display: none;

        @include mobile {
            display: none;
        }
    }
}
