@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable number-max-precision */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductReviews {
    border-bottom: unset;

    &-DropdownTitle {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    @include desktop {
        z-index: 1;
    }

    &-totalCount {
        color: #000000;
        font-weight: 500;
        margin-block-start: 20px;
        display: grid;
        grid-template-columns: 7fr 2fr;
    }

    &-reviewSorting {
        margin-block-start: -35px;

        @include mobile {
            width: 117px;
        }

        .FieldSelect-Select {
            border-bottom: unset;
        }
    }

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding: 36px 12px;
        }
    }

    &-Summary {
        align-self: stretch;
        display: grid;
        grid-template-columns: 2fr 4fr;
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-RatingStarsDetails {
        display: grid;
        gap: 15px;
        margin-block-end: 20px;
    }

    &-ReviewBy {
        color: #585858;
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }

        .ProductReviewRating {
            align-items: center;
            margin-block-start: -5px;
        }
    }

    &-RatingStarsCount {
        display: flex;
        gap: 5px;
    }

    &-Button {
        background-color: unset;
        border: unset;
        padding-inline-start: 0px;

        @include mobile {
            justify-content: unset;
        }
    }

    & &-Button {
        position: absolute;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        margin: auto;

        @include mobile {
            position: relative;
            width: 100%;
            margin-block-start: 18px;
            line-height: 20px;
        }
    }

    &-ReviewCount {
        font-size: 44px;
    }

    &-Border {
        border-bottom: 0.75px solid #D6D6D6;
    }

    &-SummaryDetails {
        margin-block-start: 10px;
        display: grid;
    }

    &-ReviewsButton {
        display: flex;

        .Button {
            &:hover {
                padding: 0;
            }
        }
    }

    &-ReviewDetails {
        display: flex;
        margin: auto;
        margin-block: auto;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        font-style: normal;
        color: #000000;

        @include mobile {
            margin-inline-start: 14px;
            line-height: 24px;
            font-size: 18px;
        }

        span {
            &::before {
                content: ' / ';
            }
        }
    }

   

    &-bar {
        // width: 100%;
        height: 4px;
        background-color: #000000;
        border-radius: 514px;
    }

    &-RatingPerson {
        color: #A7A7A7;
    }

    &-NoReviews {
        margin-block-start: 30px;
        font-size: 16px;
        min-height: 11rem;
    }

    .FieldSelect-Select {
        border-bottom: unset;
    }

    .FieldSelect-Options {
        position: absolute;
    }
}




.bar-container {
    width: 100%;
    background-color: #DEDEDE;
    text-align: center;
    height: 4px;
    margin: auto;
    border-radius: 514px;
    margin-inline-end: 10px;
}


.ProductReviewList {
    display: unset;

    .ProductReviewItem {
        display: flex;
        flex-direction: column;
        border: unset;
        padding-inline-start: 0px;
    }
}

.StarIcon {
    fill: #000000;
    margin-block-start: auto;
    margin-inline-end: 9px;
}

