@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductListPage {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-auto-rows: max-content;
    grid-column-gap: 12px;

    @include tablet {
        grid-template-columns: repeat(3, 1fr);
    }

    @include mobile {
        grid-column-gap: 14px;
        grid-template-columns: 1fr 1fr;
        border: 0;
        padding-inline: 14px;
    }

    @include desktop {
        grid-column: 2;
    }

    &-Offset {
        @include mobile {
            height: 150vh;
            inset-block-start: -150vh;
        }

        height: 100vh;
        pointer-events: none;
        inset-block-start: -100vh;
        position: absolute;
        inset-inline-start: 0;
        width: 100%;

        &::before {
            display: none;
        }
    }

    .ProductCard-Figure .Image-Image {
        object-fit: cover;
    }
}
