@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductListWidget {
    @include desktop {
        grid-column: 2;
        padding-block-end: 24px;
        min-height: auto;
    }

    &-Page {
        display: grid;
        grid-auto-rows: max-content;
        grid-column-gap: 2.5rem;
        grid-row-gap: 1rem;

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(4, 1fr);
        }

        @include wide-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include mobile {
            grid-column-gap: 16px;
            grid-template-columns: 1fr 1fr;
            border: 0;
            padding: 1.5rem;
        }
    }
}
