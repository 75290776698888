@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductPage {
    .ProductGallery {
        display: flex;
        flex-direction: row-reverse;
        column-gap: 20px;
        margin-inline: 0;
        padding-block-start: 0px;

        @include desktop {
            position: sticky;
            inset-block-start: 15rem;
            justify-content: center;
            align-items: center;
        }

        @include narrow-desktop {
            min-width: unset;
        }

        @include mobile {
            height: 300px;
            margin-block-end: 16px;
            margin: 0%;
        }

        &-BrandLogo {
            display: none;
            position: absolute;
            inset-block-end: 20px;
            z-index: 4;
            border-radius: 50%;
            background-color: #fff;
            padding: 9px;
            width: 75px;
            height: 75px;
            transition: inset-block-end 0.5s;
            inset-inline-end: 20px;

            @include mobile() {
                inset-block-end: 14px;
                inset-inline-end: 14px;
                width: 48px;
                height: 48px;
            }

            &.ProductGallery-BrandLogo_layout_grid {
                img {
                    width: 100%;
                }
            }
        }

        &-Additional {
            flex: 0 0 auto;
            height: 500px;
            width: 22%;
            overflow-y: hidden;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 10px;

            &::-webkit-scrollbar {
                display: none;
            }

            @include mobile {
                display: none;
            }

            .CarouselScroll-Content {
                gap: 0px;
            }

            .CarouselScrollItem {
                width: 160px;
                height: 186px;
            }

            @media screen and (max-width: 1025px) {
                display: none;
            }

            &_isImageZoomPopupActive {
                position: absolute;
                inset-block-end: 20px;
                inset-inline-start: 20px;

                .CarouselScrollItem {
                    filter: opacity(0.75);

                    &_isActive {
                        border-width: 2px;
                        filter: opacity(1);
                    }
                }
            }

            &_isWithEmptySwitcher {
                height: 66px;
            }
        }

        &-Additional-image-overlay {
            position: relative;
        }

        &-SliderWrapper {
            width: 498px;
            position: relative;

            .Slider-CounterWithArrows {
                display: flex;
                position: absolute;
                width: 100%;
                align-items: center;
                justify-content: center;
                inset-block-end: 10px;
                inset-inline-end: 10px;
                max-width: 112px;
                background: var(--color-white);
                border-radius: 8px;

                @include mobile {
                    inset-inline-end: 10px;
                    inset-block-end: 10px;
                }
                
                .Slider-Arrows {
                    width: 20px;
                    height: 20px;
                }

                .Slider-Counter {
                    color: #000;
                    font-weight: 600;
                    background: none;
                    padding: 8px;
                    display: flex;
                    font-size: 16px;
                    align-items: center;
                    justify-content: center;
                    position: static;
    
                    @include mobile {
                        max-width: 95px;
                        font-size: 18px;
                    }

                    @include smallmobile {
                        max-width: 95px;
                        font-size: 16px;
                    }
                }

                svg {
                    width: 20px;
                    height: 20px;
                }
            }

            @include mobile() {
                width: unset;
                flex: 1;
            }
        }

        &-Slider {
            height: 100%;
            border-radius: 8px;
            opacity: var(--sliderOpacity);

            &_isImageZoomPopupActive {
                cursor: pointer;
            }

            &_isZoomInCursor {
                cursor: default;
            }
        }

        .react-transform-component,
        .react-transform-element,
        &-SliderImage {
            width: 100%;
            height: 100%;
            border-radius: 8px;
            pointer-events: none;
            padding-block-end: 100%;
        }

        &-PopUp-slider {
            display: flex;
            position: fixed;
            align-items: center;
            flex-direction: column;
            justify-content: space-evenly;
            z-index: 999;
            inset-inline-start: 0;
            inset-block-start: 0;
            width: 100%;
            height: 100%;
            overflow: auto;
            background: #00000060 0% 0% no-repeat padding-box;
            padding: 4rem 28rem;
    
            @include mobile {
                z-index: 4;
            }
    
            img {
                width: 100%;
            }
    
            &-Hide {
                display: none;
            }
        }

        &-openSliderPopupIcon {
            position: absolute;
            inset-block-start: 10px;
            inset-inline-end: 10px;
            background: #fff;
            border-radius: 8px;
            padding: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    
        &-PopUp-ProductSlider {
            width: 100%;
        }
    
        &-PopUp-Content {
            position: relative;
            margin: auto;
            padding: 0;
            max-width: 583px;
            width: 100%;
            height: 100%;
    
            img {
                width: 100%;
                height: 100%;
            }
        }
    
        &-slides-active {
            display: block;
            height: 100%;
            padding-block-end: 100%;
    
            img {
                position: absolute;
                height: 100%;
            }
        }
    
        &-slides {
            display: none;
        }
    
        &-prev,
        &-next {
            position: absolute;
            width: 54px;
            height: 54px;
            background: var(--color-white) 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000014;
            border-radius: 36px;
            opacity: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 5rem;
            inset-block-start: 50%;
            cursor: pointer;
    
            @include mobile {
                background: none;
                box-shadow: none;
                height: 0;
            }
    
            img {
                width: 7px;
                height: 14px;
            }
        }
    
        &-prev {
            @include desktop {
                inset-inline-start: 0;
            }
    
            @include mobile {
                inset-inline-start: 1.7rem;
            }
        }
    
        &-next {
            @include desktop {
                inset-inline-end: 0;
            }
    
            @include mobile {
                inset-inline-end: 1.7rem;
            }
        }

        &-PopUp-Thumbnailslider {
            display: flex;
            max-width: 580px;
            gap: 2rem;
            overflow-x: scroll;
        }

        &-ThumbnailSlides {
            display: flex;
            transition: border-color var(--animation-speed), filter var(--animation-speed);
            cursor: pointer;
            width: 100px;
            aspect-ratio: 1;
            height: 100px;
            justify-content: center;

            &-active {
                border: 1px solid #000;
                padding: 10px;
                display: flex;
                transition: border-color var(--animation-speed), filter var(--animation-speed);
                cursor: pointer;
                width: 100px;
                aspect-ratio: 1;
                height: 100px;
                justify-content: center;
            }
        }
    
        &-close-cursor {
            cursor: pointer;
            position: absolute;
            inset-inline-end: 0;
            inset-block-start: -36px;
            color: var(--color-white);
            font-size: 30px;
            padding: 0 0;
            margin-block-end: 11px;
    
            @include mobile {
                position: absolute;
                inset-inline-end: 26px;
                inset-block-start: 17px;
                z-index: 2;
            }
    
            .close-img {
                width: 30px;
                height: 30px;
                background-color: var(--color-white);
                display: flex;
                justify-content: center;
                align-items: center;
    
                svg {
                    width: 20px;
                    height: 20px;
                }
    
                @include mobile {
                    width: 31px;
                    height: 31px;
                    padding: 4px;
                    border: 1px solid #b4b5b4;
                }
    
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.ImageZoomPopup {
    .ProductGallery {
        &-BrandLogo {
            position: absolute;
            inset-block-end: 20px;
            z-index: 4;
            border-radius: 50%;
            background-color: #fff;
            padding: 9px;
            width: 75px;
            height: 75px;
            transition: inset-block-end 0.5s;
            inset-inline-end: 20px;

            @include mobile() {
                inset-block-end: 14px;
                inset-inline-end: 14px;
                width: 48px;
                height: 48px;
            }
        }
    }

    .Productimageoverlayactions {
        &-main {
            display: none;
        }
    }
}
