@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductReviewForm {
    &-Wrapper {
        @include desktop {
            display: grid;
            grid-template-columns: 1fr 2fr;
            grid-gap: 24px;
        }
    }

    &-RatingWrapper {
        display: flex;
        flex-direction: column;

        .FieldGroup-Wrapper {
            &:not(:last-child) {
                margin-block-end: 24px;
            }

            .FieldGroup {
                &_hasError, &_isValid {
                    padding-inline-start: 0;
                    border: none;
                }
            }
        }
    }

    &-ProductName {
        font-weight: 700;
        font-size: 24px;

        @include mobile {
            font-size: 25px;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        row-gap: 24px;

        .Field {
            margin-block-start: 12px;

            &-ErrorMessages {
                padding-block-start: 6px;
            }
        }

        input,
        textarea {
            font-size: 12px;
        }

        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Rating {
        display: flex;
        flex-flow: row-reverse nowrap;
        margin-inline: 0;
        text-align: start;
        border: 0;
        /* stylelint-disable-next-line unknownProperties, property-no-unknown */
        white-space-collapse: discard; // alternative of `font-size: 0`

        @include mobile {
            max-width: 75%;
        }

        &:first-child {
            @include mobile {
                margin-block-start: 0;
            }
        }

        input {
            cursor: pointer;
        }
    }

    &-Legend {
        border-block-end: 0;
        padding-block-end: 4px;
        font-weight: 700;
        font-size: 13px;

        @include mobile {
            padding-block-end: 4px;
            font-size: 15px;
        }
    }

    &-Legend::after {
        content: ' *';
        white-space: pre;
        font-weight: bold;
        color: var(--required-text-color);
    }

    &-ErrorMessage {
        color: var(--primary-error-color);
        font-size: 12px;
        text-align: start;
        padding-block-start: 0;
    }

    & &-Button {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 28px;
            width: 100%;
        }
    }

    &-Field {
        margin-block-start: 0;
        padding-block-end: 0;
    }

    input,
    textarea {
        width: 100%;
        padding: unset;
    }

    textarea {
        margin-block-end: -3px;
        max-height: 36px;
        margin-block-start: 13px;
    }
}
