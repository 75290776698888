@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.Product {
    &-productPopUpWrapper {
        padding: 16px 16px 16px 16px;
    }

    &-productPopText {
        display: flex;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
        padding-block-end: 12px;
    }

    &-productPopErrorMessage {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 16px 16px 16px 16px;
        background: #EBCF88;
        border-radius: 8px;
        margin-block-end: 16px;
    }

    &-popup {
        display: flex;
        background: #fff;
        flex-direction: column;
        border-radius: 8px;
        max-width: 422px;
        width: 100%;

        @include mobile {
            max-width: 360px;
            margin-inline: 2rem;
        }
    }

    &-productPopMessage {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
        margin-block-end: 16px;
    }
    
    &-productBasketButton {
        width: 100%;
        background: #73A054;
        padding: 12px 16px 12px 16px;
        border-radius: 8px;
        color: #ffffff;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
        margin-block-end: 16px;
        cursor: pointer;
    }

    &-productContinueButton {
        width: 100%;
        background: #ffffff;
        padding: 12px 16px 12px 16px;
        border-radius: 8px;
        color: #000;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
        border: 2px solid #3E3D30;
        cursor: pointer;
        text-align: center;

        a {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }
        
        a:hover {
            color: #000;
            font-size: 16px;
            font-weight: 600;
        }
    }
}
