@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    &-Title {
        font-weight: bold;

        @include mobile {
            padding-block-start: 4px;
            padding-block-end: 0;
            padding-inline: 16px;
            margin-block-start: 40px;
        }

        @include desktop {
            padding-block-start: 12px;
            padding-block-end: 36px;
            padding-inline: 0;
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }

    .swiper {
        padding: 0 4.2rem;

        @include mobile {
            padding: 0 1.6rem;
        }
    }
}
